body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

code.example {
  counter-increment: line;
  display: block;
}

code.example:before {
  content: counter(line, decimal-leading-zero) ' ';
  -webkit-user-select: none;
  background: #f0f0f0;
  margin-right: 8px;
  margin-left: 8px;
  border-right: 1px #e0e0e0 solid;
}

tr.clickable:hover {
  cursor: pointer;
}
